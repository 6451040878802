import React from "react";
import { Link } from "react-router-dom";

import Script from "dangerous-html/react";
import { Helmet } from "react-helmet";

import "./home.css";

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Grem post</title>
        <meta property="og:title" content="Grem Post" />
      </Helmet>
      <header data-thq="thq-navbar" className="home-navbar">
        <span className="home-logo">GremPostApp</span>
        <div
          data-thq="thq-navbar-nav"
          data-role="Nav"
          className="home-desktop-menu"
        >
          <nav
            data-thq="thq-navbar-nav-links"
            data-role="Nav"
            className="home-nav"
          >
            <Link to="/" className="home-navlink button-clean button">
              Home
            </Link>
            <Link to="/feature" className="home-navlink1 button-clean button">
              Features
            </Link>
            <Link to="/pricing" className="home-navlink2 button-clean button">
              Pricing
            </Link>
          </nav>
        </div>
        <div data-thq="thq-burger-menu" className="home-burger-menu">
          <button className="button home-button">
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </button>
        </div>
        <div data-thq="thq-mobile-menu" className="home-mobile-menu">
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="home-nav1"
          >
            <div className="home-container1">
              <span className="home-logo1">Character</span>
              <div data-thq="thq-close-menu" className="home-menu-close">
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="home-nav2"
            >
              <span className="home-text">About</span>
              <span className="home-text01">Features</span>
              <span className="home-text02">Pricing</span>
              <span className="home-text03">Team</span>
              <span className="home-text04">Blog</span>
            </nav>
            <div className="home-container2">
              <button className="home-login button">Login</button>
              <button className="button">Register</button>
            </div>
          </div>
          <div className="home-icon-group">
            <svg viewBox="0 0 950.8571428571428 1024" className="home-icon04">
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon06">
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg viewBox="0 0 602.2582857142856 1024" className="home-icon08">
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </div>
      </header>
      <section className="home-hero">
        <div className="home-heading">
          <h1 className="home-header">Social Media Management tool</h1>
          <p className="home-caption">
            Easily create, schedule, publish, and manage social media content in
            one place, with no limits or monthly subscription fees.
          </p>
        </div>
        <div className="home-buttons">
          <button className="home-learn button-clean button">Learn more</button>
        </div>
      </section>
      <section className="home-description">
        <img
          alt="image"
          src="/screenshot%202024-03-22%20alle%2023.51.48-1500w.png"
          className="home-image"
        />
        <div className="home-container3">
          <div className="home-description1">
            <div className="home-content">
              <p className="home-paragraph">
                <span>Maximize Your Social Media Impact with GremPost</span>
                <br className="home-text06"></br>
                <span className="home-text07">
                  Secure, Efficient, and Profitable Social Media Management in
                  Just Two Steps
                </span>
              </p>
            </div>
            <div className="home-links"></div>
          </div>
        </div>
      </section>
      <section className="home-cards">
        <div className="home-row">
          <div className="home-card">
            <div className="home-avatar">
              <img
                alt="image"
                src="/Avatars/avatar.svg"
                className="home-avatar1"
              />
            </div>
            <div className="home-main">
              <div className="home-content1">
                <h1 className="home-header01">
                  <span>Fast Social Media Profits</span>
                  <br></br>
                  <span>
                    Optimize your team&apos;s social media strategy with
                    Grempost or launch your SaaS quickly and start generating
                    revenue effortlessly.
                  </span>
                </h1>
              </div>
            </div>
          </div>
          <div className="home-card1">
            <div className="home-avatar2">
              <img
                alt="image"
                src="/Avatars/default-avatar.svg"
                className="home-avatar3"
              />
            </div>
            <div className="home-main1">
              <div className="home-content2">
                <h2 className="home-header02">
                  Build your audience, create leads, and grow your business with
                  our powerful and easy-to-use tools.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="home-card2">
          <div className="home-avatar4">
            <img
              alt="image"
              src="/Avatars/light-avatar.svg"
              className="home-avatar5"
            />
          </div>
          <div className="home-row1">
            <div className="home-main2">
              <div className="home-content3">
                <h2 className="home-header03">
                  <span>Powerful features that are easy to use</span>
                  <br></br>
                </h2>
                <p className="home-description2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliquat
                  enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi
                </p>
              </div>
              <button className="home-learn1 button">
                <span className="home-text13">Learn more</span>
                <img
                  alt="image"
                  src="/Icons/arrow-2.svg"
                  className="home-image1"
                />
              </button>
            </div>
            <img alt="image" src="/group%202262.svg" className="home-image2" />
          </div>
        </div>
      </section>
      <section className="home-project">
        <div className="home-understand">
          <div className="home-content4">
            <div className="home-heading1">
              <h2 className="home-header04">
                <span>Powerful features that are easy to use</span>
                <br></br>
              </h2>
              <p className="home-header05">
                <span>
                  GremPost provides visually appealing and actionable insights
                  on follower activity and post engagement to help you grow your
                  social media presence. The insights are presented in an
                  easy-to-read format that makes it simple to understand and
                  apply the information to your strategy.
                </span>
                <br></br>
              </p>
            </div>
          </div>
          <img alt="image" src="/group%202415.svg" className="home-image3" />
        </div>
      </section>
      <section className="home-roadmap">
        <div className="home-heading2">
          <h2 className="home-header06">
            Easily publish content to all of your social media accounts with
            just one click.
          </h2>
        </div>
        <div className="home-list">
          <div className="home-step">
            <span className="home-caption1">01</span>
            <div className="home-heading3">
              <h2 className="home-header07">Customize posts</h2>
              <p className="home-header08">
                Easily tailor your posts to the specific format and requirements
                of each social media channel for optimal results.
              </p>
            </div>
          </div>
          <div className="home-step1">
            <span className="home-caption2">02</span>
            <div className="home-heading4">
              <h2 className="home-header09">
                <span>Preview post</span>
                <br></br>
              </h2>
              <p className="home-header10">
                <span>
                  Make sure your posts look great with our preview feature
                </span>
                <br></br>
                <br></br>
              </p>
            </div>
          </div>
          <div className="home-step2">
            <span className="home-caption3">03</span>
            <div className="home-heading5">
              <h2 className="home-header11">
                <span>Post versions</span>
                <br></br>
              </h2>
              <p className="home-header12">
                <span>Experiment with different versions of your content</span>
                <br></br>
              </p>
            </div>
          </div>
          <div className="home-step3">
            <span className="home-caption4">04</span>
            <div className="home-heading6"></div>
          </div>
        </div>
      </section>
      <section className="home-get-yours">
        <div className="home-row2">
          <div className="home-column">
            <div className="home-card3">
              <img
                alt="image"
                src="/Characters/character-9.svg"
                className="home-image4"
              />
            </div>
          </div>
          <div className="home-column1">
            <div className="home-card4">
              <img
                alt="image"
                src="/Characters/character-10.svg"
                className="home-image5"
              />
            </div>
          </div>
        </div>
        <div className="home-column2">
          <div className="home-card5">
            <div className="home-content5">
              <h2 className="home-header13">
                <span>Get your hands on the best</span>
                <br></br>
                <span>social media management software today!</span>
              </h2>
            </div>
            <button
              className="home-button1 button"
              onClick={() => window.open("https://www.gremcompany.com/")}
            >
              Reach Us
            </button>
          </div>
        </div>
      </section>
      <footer className="home-footer">
        <div className="home-main3">
          <div className="home-branding">
            <div className="home-heading7">
              <h2 className="home-logo2">Grem Post</h2>
            </div>
            <div className="home-socials"></div>
          </div>
          <div className="home-socials1">
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-twitter social button"
            >
              <img
                alt="image"
                src="/Icons/twitter.svg"
                className="home-image6"
              />
            </a>
            <a
              href="https://discord.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-discord social button"
            >
              <img
                alt="image"
                src="/Icons/discord.svg"
                className="home-image7"
              />
            </a>
          </div>
        </div>
        <span className="home-copyright">
          © 2022 Grem Post. All Rights Reserved.{" "}
          <a
            href="https://smm.gremcompany.com/pages/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy{" "}
          </a>{" "}
          <a
            href="https://smm.gremcompany.com/pages/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>
        </span>
      </footer>
      <div>
        <div className="home-container5">
          <Script
            html={`<script>
 /*
  Accordion - Code Embed
  */
  const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
  const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
  const accordionIconsClosed = document.querySelectorAll('[data-role="accordion-icon-closed"]'); // All accordion closed icons
  const accordionIconsOpen = document.querySelectorAll('[data-role="accordion-icon-open"]'); // All accordion open icons

  accordionContents.forEach((accordionContent) => {
      accordionContent.style.display = "none"; //Hides all accordion contents
  });

  accordionIconsClosed.forEach((icon) => {
    icon.style.display = "flex"
  })

  accordionIconsOpen.forEach((icon) => {
    icon.style.display = "none"
  })

  accordionContainers.forEach((accordionContainer, index) => {
      accordionContainer.addEventListener("click", () => {
          if (accordionContents[index].style.display === "flex") {
              // If the accordion is already open, close it
              accordionContents[index].style.display = "none";
              accordionIconsClosed[index].style.display = "flex";
              accordionIconsOpen[index].style.display = "none"
          } else {
              // If the accordion is closed, open it
              accordionContents.forEach((accordionContent) => {
                  accordionContent.style.display = "none"; //Hides all accordion contents
              });

              accordionIconsClosed.forEach((accordionIcon) => {
                  accordionIcon.style.display = "flex"; // Resets all icon transforms to 0deg (default)
              });

              accordionIconsOpen.forEach((accordionIcon) => {
                accordionIcon.style.display = "none";
              })
              
              accordionContents[index].style.display = "flex"; // Shows accordion content
              accordionIconsClosed[index].style.display = "none"; // Rotates accordion icon 180deg
              accordionIconsOpen[index].style.display = "flex";
          }
      });
  });
</script>
`}
          ></Script>
        </div>
      </div>
    </div>
  );
};

export default Home;
