// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature-card1-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card1-feature-card:hover {
  transform: scale(1.02);
}
.feature-card1-text {
  color: #ffffff;
  margin-bottom: var(--dl-space-space-twounits);
}
.feature-card1-image {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
















`, "",{"version":3,"sources":["webpack://./src/components/feature-card1.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mCAAmC;EACnC,uCAAuC;EACvC,kDAAkD;EAClD,gBAAgB;EAChB,uBAAuB;EACvB,sBAAsB;EACtB,2BAA2B;AAC7B;AACA;EACE,sBAAsB;AACxB;AACA;EACE,cAAc;EACd,6CAA6C;AAC/C;AACA;EACE,iCAAiC;EACjC,kCAAkC;EAClC,iBAAiB;EACjB,8CAA8C;AAChD","sourcesContent":[".feature-card1-feature-card {\n  width: 100%;\n  display: flex;\n  padding: var(--dl-space-space-unit);\n  max-width: var(--dl-size-size-maxwidth);\n  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);\n  transition: 0.3s;\n  align-items: flex-start;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n.feature-card1-feature-card:hover {\n  transform: scale(1.02);\n}\n.feature-card1-text {\n  color: #ffffff;\n  margin-bottom: var(--dl-space-space-twounits);\n}\n.feature-card1-image {\n  width: var(--dl-size-size-medium);\n  height: var(--dl-size-size-medium);\n  object-fit: cover;\n  border-radius: var(--dl-radius-radius-radius4);\n}\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
